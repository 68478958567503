import axios from 'axios';

const apiService = {
  // API Configuration
  config: {
    appId: process.env.REACT_APP_APP_ID ,
    accessKey: process.env.REACT_APP_ACCESS_KEY ,
    region: process.env.REACT_APP_REGION ,
    apiKey: process.env.REACT_APP_IMGBB_API_KEY 
  },

  // Upload image to ImgBB
  async uploadImage(file, progressCallback) {
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await axios.post(
        `https://api.imgbb.com/1/upload?key=${this.config.apiKey}`,
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            if (progressCallback) {
              progressCallback(percentCompleted);
            }
          }
        }
      );
      return response.data.data.url;
    } catch (error) {
      throw new Error(`Failed to upload image: ${error.message}`);
    }
  },

  // Create new group
  async createGroup(data) {
    try {
      const response = await axios.post(
        `https://${this.config.region}.appsheet.com/api/v2/apps/${this.config.appId}/tables/Nhom/Action`,
        {
          Action: 'Add',
          Properties: {
            Locale: 'vi-VN',
            Timezone: 'Asia/Ho_Chi_Minh'
          },
          Rows: [data]
        },
        {
          headers: {
            'ApplicationAccessKey': this.config.accessKey,
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(`Failed to create group: ${error.message}`);
    }
  },

  // Add images to group
  async addImages(images) {
    try {
      const response = await axios.post(
        `https://${this.config.region}.appsheet.com/api/v2/apps/${this.config.appId}/tables/Image/Action`,
        {
          Action: 'Add',
          Properties: {
            Locale: 'vi-VN',
            Timezone: 'Asia/Ho_Chi_Minh'
          },
          Rows: images
        },
        {
          headers: {
            'ApplicationAccessKey': this.config.accessKey,
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(`Failed to add images: ${error.message}`);
    }
  }
};

export default apiService;
import React, { useState, useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import apiService from './services/api';


import { UploadIcon, XCircleIcon, PlusCircleIcon } from '@heroicons/react/solid';

const App = () => {
  // States
  const [mainImage, setMainImage] = useState(null);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [progressText, setProgressText] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  // Thêm vào phần states
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  // Refs
  const mainImageInputRef = useRef(null);
  const additionalImagesInputRef = useRef(null);
  const formRef = useRef(null);

  // Toast notification helper
  const showToast = (message, type = 'success') => {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  // File validation
  const validateFile = (file) => {
    console.log('Validating file:', file);

    if (!file) {
      showToast('Không tìm thấy file', 'error');
      return false;
    }

    // Kiểm tra định dạng file
    const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
    console.log('File type:', file.type);

    if (!allowedTypes.includes(file.type)) {
      showToast(`Định dạng file không hợp lệ. Chỉ chấp nhận: ${allowedTypes.join(', ')}`, 'error');
      return false;
    }

    // Kiểm tra kích thước
    const maxSize = 5 * 1024 * 1024; // 5MB
    console.log('File size:', file.size);

    if (file.size > maxSize) {
      showToast(`File quá lớn. Tối đa ${Math.floor(maxSize / 1024 / 1024)}MB`, 'error');
      return false;
    }

    return true;
  };

  // Handle main image change
  // Thay đổi hàm handleMainImageChange
  const handleMainImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Log để debug
    console.log('Selected file:', file);

    if (!validateFile(file)) return;

    try {
      // Kiểm tra xem URL.createObjectURL có hoạt động không
      const objectUrl = URL.createObjectURL(file);
      console.log('Created URL:', objectUrl);

      setMainImage(file);
    } catch (error) {
      console.error('Error creating object URL:', error);
      showToast('Có lỗi khi xử lý file ảnh', 'error');
    }
  };
  // Handle main image drop
  const handleMainImageDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file && validateFile(file)) {
      setMainImage(file);
    }
  };

  // Handle additional images change
  const handleAdditionalImagesChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter(validateFile);
    setAdditionalImages(prev => [...prev, ...validFiles]);
  };

  // Handle additional images drop
  const handleAdditionalImagesDrop = (e) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    const validFiles = files.filter(validateFile);
    setAdditionalImages(prev => [...prev, ...validFiles]);
  };

  // Remove images handlers
  const removeMainImage = () => {
    setMainImage(null);
    if (mainImageInputRef.current) {
      mainImageInputRef.current.value = '';
    }
  };

  const removeAdditionalImage = (index) => {
    setAdditionalImages(prev => prev.filter((_, i) => i !== index));
  };

  // Form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Get form data
    const tenNhom = e.target.tenNhom.value.trim();
    const ghiChu = e.target.ghiChu.value.trim();

    // Validate form
    if (!tenNhom) {
      showToast('Vui lòng nhập tên nhóm', 'error');
      return;
    }

    if (!mainImage) {
      showToast('Vui lòng chọn ảnh đại diện', 'error');
      return;
    }

    setIsLoading(true);
    try {
      // Upload main image
      setProgressText('Đang tải lên ảnh đại diện...');
      const mainImageUrl = await apiService.uploadImage(mainImage, (percent) => {
        setProgress(percent);
      });

      // Create group
      setProgressText('Đang tạo nhóm mới...');
      setProgress(50);

      const nhomId = `NH_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

      const groupData = {
        'ID': nhomId,
        'Tên nhóm': tenNhom,
        'Hình nhóm': mainImageUrl,
        'Ghi chú': ghiChu
      };

      await apiService.createGroup(groupData);

      // Upload additional images if any
      if (additionalImages.length > 0) {
        setProgressText('Đang tải lên các hình ảnh khác...');
        setProgress(75);

        const imagePromises = additionalImages.map(async (file, index) => {
          const imageUrl = await apiService.uploadImage(file, (percent) => {
            const overallProgress = 75 + (percent * 0.25 / additionalImages.length);
            setProgress(Math.min(overallProgress, 100));
            setProgressText(`Đang tải lên hình ảnh ${index + 1}/${additionalImages.length}...`);
          });

          return {
            'KEY': nhomId,
            'HINH ANH': imageUrl
          };
        });

        const imageRows = await Promise.all(imagePromises);
        await apiService.addImages(imageRows);
      }

      showToast('Tạo nhóm và upload hình ảnh thành công!');

      // Reset form
      formRef.current.reset();
      setMainImage(null);
      setAdditionalImages([]);

    } catch (error) {
      console.error('Submit error:', error);
      showToast(error.message || 'Có lỗi xảy ra khi xử lý yêu cầu!', 'error');
    } finally {
      setIsLoading(false);
      setProgress(0);
      setProgressText('');
    }
  };

  return (
    <div className="bg-gradient-to-br from-gray-50 to-gray-100 min-h-screen flex items-center justify-center p-4">
      <ToastContainer />

      <div className="max-w-2xl w-full bg-white rounded-xl shadow-lg p-8">
        <div className="flex items-center justify-center mb-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-800 mb-2">Thêm Nhóm Mới</h2>
            <p className="text-gray-600">Điền thông tin và tải lên hình ảnh cho nhóm của bạn</p>
          </div>
        </div>

        <form ref={formRef} onSubmit={handleSubmit} className="space-y-6">
          {/* Tên nhóm input */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Tên nhóm <span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              name="tenNhom"
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
              placeholder="Nhập tên nhóm"
              required
            />
          </div>

          {/* Ghi chú textarea */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Ghi chú</label>
            <textarea
              name="ghiChu"
              rows="3"
              className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all resize-none"
              placeholder="Nhập ghi chú (nếu có)"
            />
          </div>

          {/* Main image upload */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">
              Hình nhóm (ảnh đại diện) <span className="text-red-500">*</span>
            </label>
            <div className="relative">
              {mainImage ? (
                <div className="relative">
                  <img
                    src={URL.createObjectURL(mainImage)}
                    alt="Preview"
                    className="w-full h-48 object-cover rounded-lg cursor-pointer"
                    onClick={() => setShowPreview(true)} // Thêm sự kiện click để mở preview
                  />
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      removeMainImage();
                    }}
                    className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition-colors"
                  >
                    <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              ) : (
                <div
                  onClick={() => mainImageInputRef.current?.click()}
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.currentTarget.classList.add('border-blue-500');
                  }}
                  onDragLeave={(e) => {
                    e.preventDefault();
                    e.currentTarget.classList.remove('border-blue-500');
                  }}
                  onDrop={(e) => {
                    e.preventDefault();
                    e.currentTarget.classList.remove('border-blue-500');
                    handleMainImageDrop(e);
                  }}
                  className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer hover:border-blue-500 transition-colors"
                >
                  <input
                    ref={mainImageInputRef}
                    type="file"
                    onChange={handleMainImageChange}
                    accept="image/jpeg,image/png,image/gif"
                    className="hidden"
                  />
                  <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                  </svg>
                  <p className="mt-2 text-sm text-gray-600">Kéo thả hoặc click để chọn ảnh đại diện</p>
                  <p className="mt-1 text-xs text-gray-500">(JPG, PNG, GIF, tối đa 5MB)</p>
                </div>
              )}
            </div>
          </div>

          {/* Additional images upload */}
          <div className="space-y-2">
            <label className="block text-sm font-medium text-gray-700">Thêm hình ảnh khác</label>
            <div
              onClick={() => additionalImagesInputRef.current?.click()}
              onDragOver={(e) => e.preventDefault()}
              onDrop={handleAdditionalImagesDrop}
              className="border-2 border-dashed border-gray-300 rounded-lg p-6 text-center cursor-pointer hover:border-blue-500 transition-colors"
            >
              <input
                ref={additionalImagesInputRef}
                type="file"
                onChange={handleAdditionalImagesChange}
                accept="image/*"
                multiple
                className="hidden"
              />
              <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
              <p className="mt-2 text-sm text-gray-600">Kéo thả hoặc click để chọn thêm ảnh</p>
            </div>

            {/* Additional images preview */}
            <div className="grid grid-cols-3 gap-4 mt-4">
              {additionalImages.map((file, index) => (
                <div key={index} className="relative group">
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${index + 1}`}
                    className="w-full h-32 object-cover rounded-lg cursor-pointer"
                    onClick={() => setSelectedImageIndex(index)} // Mở preview khi click
                  />
                  <button
                    type="button"
                    onClick={(e) => {
                      e.stopPropagation();
                      removeAdditionalImage(index);
                    }}
                    className="absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full opacity-0 group-hover:opacity-100 transition-opacity"
                  >
                    <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              ))}
            </div>
          </div>

          {/* Submit button */}
          <button
            type="submit"
            disabled={isLoading}
            className="w-full bg-blue-600 text-white py-3 px-4 rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center space-x-2"
          >
            {isLoading ? (
              <>
                <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                </svg>
                <span>Đang xử lý...</span>
              </>
            ) : (
              <>
                <span>Tạo nhóm mới</span>
                <svg className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                </svg>
              </>
            )}
          </button>
        </form>

        {/* Progress Overlay */}
        {isLoading && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8 rounded-xl max-w-sm w-full mx-4">
              <div className="text-center">
                <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-600 mx-auto" />
                <h3 className="mt-4 text-lg font-semibold text-gray-900">Đang xử lý...</h3>
                <p className="mt-2 text-sm text-gray-600">{progressText}</p>
                <div className="mt-4 w-full bg-gray-200 rounded-full h-2.5">
                  <div
                    className="bg-blue-600 h-2.5 rounded-full transition-all duration-300"
                    style={{ width: `${progress}%` }}
                  />
                </div>
                <p className="mt-2 text-xs text-gray-500">{Math.round(progress)}%</p>
              </div>
            </div>
          </div>
        )}

        {/* Success Modal */}
        {isLoading === false && progress === 100 && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8 rounded-xl max-w-sm w-full mx-4">
              <div className="text-center">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                  <svg
                    className="h-6 w-6 text-green-600"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <h3 className="mt-4 text-lg font-semibold text-gray-900">Thành công!</h3>
                <p className="mt-2 text-sm text-gray-600">
                  Nhóm mới đã được tạo thành công.
                </p>
                <button
                  onClick={() => {
                    setProgress(0);
                    setProgressText('');
                  }}
                  className="mt-4 bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600 transition-colors"
                >
                  Đóng
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Error Modal */}
        {isLoading === false && progress === 0 && progressText && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-8 rounded-xl max-w-sm w-full mx-4">
              <div className="text-center">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-red-100">
                  <svg
                    className="h-6 w-6 text-red-600"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
                <h3 className="mt-4 text-lg font-semibold text-gray-900">Lỗi!</h3>
                <p className="mt-2 text-sm text-gray-600">{progressText}</p>
                <button
                  onClick={() => {
                    setProgressText('');
                  }}
                  className="mt-4 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 transition-colors"
                >
                  Đóng
                </button>
              </div>
            </div>
          </div>
        )}
        {/* Additional Images Preview Modal */}
        {selectedImageIndex !== null && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            onClick={() => setSelectedImageIndex(null)}
          >
            <div
              className="bg-white p-4 rounded-xl max-w-4xl w-full mx-4"
              onClick={e => e.stopPropagation()}
            >
              <div className="relative">
                <img
                  src={URL.createObjectURL(additionalImages[selectedImageIndex])}
                  alt={`Preview ${selectedImageIndex + 1}`}
                  className="w-full h-auto max-h-[80vh] object-contain rounded-lg"
                />
                <div className="absolute top-4 right-4 flex space-x-2">
                  {/* Nút Previous */}
                  <button
                    onClick={() => setSelectedImageIndex(prev =>
                      prev > 0 ? prev - 1 : additionalImages.length - 1
                    )}
                    className="bg-gray-800 text-white p-2 rounded-full hover:bg-gray-700 transition-colors"
                    disabled={additionalImages.length <= 1}
                  >
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                    </svg>
                  </button>

                  {/* Nút Next */}
                  <button
                    onClick={() => setSelectedImageIndex(prev =>
                      prev < additionalImages.length - 1 ? prev + 1 : 0
                    )}
                    className="bg-gray-800 text-white p-2 rounded-full hover:bg-gray-700 transition-colors"
                    disabled={additionalImages.length <= 1}
                  >
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                    </svg>
                  </button>

                  {/* Nút Close */}
                  <button
                    onClick={() => setSelectedImageIndex(null)}
                    className="bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition-colors"
                  >
                    <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>

                {/* Image Counter */}
                <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 bg-black bg-opacity-50 text-white px-3 py-1 rounded-full">
                  {selectedImageIndex + 1} / {additionalImages.length}
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Image Preview Modal */}
        {showPreview && mainImage && (
          <div
            className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
            onClick={() => setShowPreview(false)} // Click bên ngoài để đóng
          >
            <div
              className="bg-white p-4 rounded-xl max-w-4xl w-full mx-4"
              onClick={e => e.stopPropagation()} // Ngăn sự kiện click lan ra ngoài
            >
              <div className="relative">
                <img
                  src={URL.createObjectURL(mainImage)}
                  alt="Preview"
                  className="w-full h-auto max-h-[80vh] object-contain rounded-lg"
                />
                <button
                  onClick={() => setShowPreview(false)}
                  className="absolute top-4 right-4 bg-red-500 text-white p-2 rounded-full hover:bg-red-600 transition-colors"
                >
                  <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;